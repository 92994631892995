
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFormField, MultiFileUploader } from 'client-website-ts-library/types/Forms/FieldTypes';
import { FormSubmissionValidationError, FormValue } from 'client-website-ts-library/types/Forms';
import { Utils } from 'client-website-ts-library/util';
import { Logger, LogLevel } from 'client-website-ts-library/services';

import FormMultiFileUploaderGenericFileModule from './Modules/FormMultiFileUploaderGenericFileModule.vue';
import FormMultiFileUploaderImageModule from './Modules/FormMultiFileUploaderImageModule.vue';
import FormMultiFileUploaderVideoModule from './Modules/FormMultiFileUploaderVideoModule.vue';

interface MFUItem {
  file: File;
  id: string;
}

@Component({
  components: {
    FormMultiFileUploaderGenericFileModule,
    FormMultiFileUploaderImageModule,
    FormMultiFileUploaderVideoModule,
  },
})
export default class FormMultiFileUploaderField extends Vue implements IFormField {
  @Prop()
  private readonly def!: MultiFileUploader;

  @Prop()
  private readonly errors!: FormSubmissionValidationError[];

  private files: MFUItem[] = [];

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  get totalFileSize(): number {
    return this.files.map((f) => f.file.size).reduce((acc, current) => acc + current, 0);
  }

  private removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  private handleAddFiles(files: File[]): void {
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];

      if (this.totalFileSize + file.size <= this.def.TotalMaxFileSize) {
        this.files.push({
          file,
          id: Utils.GetGuid(),
        });
      } else {
        Logger.Log(LogLevel.Debug, '[MFU]', 'Max file size will be exceded, skipping file.');
      }
    }
  }

  private formatFileSize(size: number): string {
    let b = size;

    let u = 0;
    const s = 1024;

    while (b >= s || -b >= s) {
      b /= s;
      u += 1;
    }

    return `${u ? `${b.toFixed(1)} ` : b} ${' KMGTPEZY'[u]}B`;
    // return (u ? b.toFixed(1) + ' ' : b) + ' KMGTPEZY'[u] + 'B';
  }

  getValue(): FormValue {
    return {
      Key: this.def.Key,
      Value: this.files.map((f) => f.file),
    };
  }

  setValue(value: FormValue) {
    if (value.Value instanceof Array) {
      this.files = (value.Value as unknown[])
        .filter((v: unknown) => v instanceof File)
        .map((v: unknown) => {
          const val = v as File;

          return { file: val, id: Utils.GetGuid() } as MFUItem;
        });
    }
  }
}
